import { Directive, ElementRef, Input, OnChanges, OnInit, Renderer2 } from '@angular/core';
import { TYPE_FILE } from '@core/enum/attachment';

@Directive({
  selector: '[appFileIcon]',
  standalone: true
})
export class FileIconDirective implements OnChanges, OnInit {
  @Input() fileType?: string = '';

  private readonly fileIcons: { [key: string]: string } = {
    [TYPE_FILE.PDF]: 'assets/images/icons/file-pdf.svg',
    [TYPE_FILE.DOCX]: 'assets/images/icons/file-word.svg',
    [TYPE_FILE.XLSX]: 'assets/images/icons/file-excel.svg',
    [TYPE_FILE.PNG]: 'assets/images/icons/file-png.svg',
    [TYPE_FILE.MP4]: 'assets/images/icons/file-mp4.svg',
    [TYPE_FILE.JPEG]: 'assets/images/icons/file-jpeg.svg'
  };

  constructor(
    private el: ElementRef,
    private renderer: Renderer2
  ) {}

  ngOnChanges() {
    this.updateIcon();
  }
  ngOnInit(): void {}

  private updateIcon() {
    if (this.fileType) {
      const iconSrc = this.fileIcons[this.fileType];
      this.renderer.setAttribute(this.el.nativeElement, 'src', iconSrc);
      this.renderer.setAttribute(this.el.nativeElement, 'alt', this.fileType);
    }
  }
}
